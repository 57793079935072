import { BrowserWallet } from '@meshsdk/core'
import axios from 'axios'
import { allowedNetwork, baseURL, loginMessage } from 'config/app.config'
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { toast } from 'react-hot-toast'

interface WalletContextI {
  hasConnectedWallet: boolean
  connectedWalletInstance: BrowserWallet
  connectedWalletName: string
  connectingWallet: boolean
  connectWallet?: (walletName: string) => Promise<void>
  disconnect?: () => void
  error?: unknown
}

const INITIAL_STATE = {
  walletName: '',
  walletInstance: {} as BrowserWallet
}

const useWalletStore = () => {
  const [error, setError] = useState<unknown>(undefined)
  const [connectingWallet, setConnectingWallet] = useState<boolean>(false)

  const [connectedWalletInstance, setConnectedWalletInstance] =
    useState<BrowserWallet>(INITIAL_STATE.walletInstance)

  const [connectedWalletName, setConnectedWalletName] = useState<string>(
    INITIAL_STATE.walletName
  )

  const initConnection = async (walletName: string) => {
    const walletInstance = await BrowserWallet.enable(walletName)
    const networkId = await walletInstance.getNetworkId()

    if (!allowedNetwork.includes(networkId.toString())) {
      throw new Error('Wallet network not allowed')
    }

    const stakeAddresses = await walletInstance.getRewardAddresses()
    if (stakeAddresses.length === 0) {
      throw new Error('Unexpected error')
    }

    const baseAddress = await walletInstance.getUsedAddresses()
    if (baseAddress.length === 0) {
      throw new Error('Unexpected error')
    }

    return {
      walletInstance,
      stakeAddress: stakeAddresses[0],
      baseAddress: baseAddress[0]
    }
  }

  const connectWallet = useCallback(async (walletName: string) => {
    setConnectingWallet(true)

    try {
      const connection = await initConnection(walletName)

      const signatureObject = await connection.walletInstance.signData(
        connection.baseAddress,
        loginMessage
      )

      await axios({
        baseURL,
        url: 'api/auth/login',
        method: 'post',
        data: {
          signature: signatureObject.signature,
          key: signatureObject.key,
          wallet: connection.baseAddress,
          stakeKey: connection.stakeAddress,
          walletName
        }
      })

      setConnectedWalletInstance(connection.walletInstance)
      setConnectedWalletName(walletName)
      setError(undefined)
    } catch (error) {
      console.log(error)
      if (error instanceof Error) {
        toast.error(error.message)
      }
      setError(error)
    }

    setConnectingWallet(false)
  }, [])

  const restoreSession = async () => {
    setConnectingWallet(true)

    try {
      const {
        data: { data }
      } = await axios({
        baseURL,
        method: 'get',
        url: 'api/auth/restore'
      })

      const connection = await initConnection(data.walletName)
      if (connection.stakeAddress !== data.wallet) {
        await axios({
          baseURL,
          url: 'api/auth/logout',
          method: 'get'
        })
        throw new Error('Unauthorized public key')
      }

      setConnectedWalletInstance(connection.walletInstance)
      setConnectedWalletName(data.walletName)
      setError(undefined)
    } catch (err) {}
    setConnectingWallet(false)
  }

  useEffect(() => {
    restoreSession()
  }, [])

  const disconnect = useCallback(async () => {
    await axios({
      baseURL,
      url: 'api/auth/logout',
      method: 'get'
    })
    setConnectedWalletName(INITIAL_STATE.walletName)
    setConnectedWalletInstance(INITIAL_STATE.walletInstance)
  }, [])

  return {
    hasConnectedWallet: INITIAL_STATE.walletName !== connectedWalletName,
    connectedWalletInstance,
    connectedWalletName,
    connectingWallet,
    connectWallet,
    disconnect,
    error
  }
}

const WalletContext = createContext<WalletContextI>({
  hasConnectedWallet: false,
  connectedWalletInstance: INITIAL_STATE.walletInstance,
  connectedWalletName: INITIAL_STATE.walletName,
  connectingWallet: false
})

const WalletProvider = ({
  children
}: {
  children: ReactElement | Array<ReactElement>
}) => {
  const store = useWalletStore()

  return (
    <WalletContext.Provider value={store}>{children}</WalletContext.Provider>
  )
}

const useWallet = () => {
  const context = useContext(WalletContext)

  if (context.connectWallet === undefined || context.disconnect === undefined) {
    throw new Error(
      "Can't call useWallet outside of the WalletProvider context"
    )
  }

  return {
    name: context.connectedWalletName,
    connecting: context.connectingWallet,
    connected: context.hasConnectedWallet,
    wallet: context.connectedWalletInstance,
    connect: context.connectWallet,
    disconnect: context.disconnect,
    error: context.error
  }
}

export { WalletProvider, useWallet }
