import { GlobalProvider } from '@contexts/global.context'
import { WalletProvider } from '@contexts/wallet.context'
import '@styles/globals.scss'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Castoro } from 'next/font/google'
import Head from 'next/head'
import React, { ReactElement, ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const font = Castoro({ subsets: ['latin'], weight: ['400'] })

function App ({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect

  const [queryClient] = React.useState(() => new QueryClient())

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <title>SPQC - NFT Factory</title>
      </Head>
      <style jsx global>
        {`
          html {
            font-family: ${font.style.fontFamily};
          }
        `}
      </style>
      <WalletProvider>
        <GlobalProvider>
          <QueryClientProvider contextSharing={true} client={queryClient}>
            {getLayout(<Component {...pageProps} />)}
          </QueryClientProvider>
        </GlobalProvider>
      </WalletProvider>
    </>
  )
}

export default App
